<template>
  <!--推送添加页面 -->
  <moe-page title="推送添加">
    <div class="df jcfe mt-30">
      <el-button type="success" icon="el-icon-download" @click="handleBatchImportTemplate()">下载excel模版</el-button>
    </div>
    <moe-form ref="pushForm" :showBack="false" :model="push" :rules="pushRules" :showClose="true">
      <el-form-item label="推文标题" prop="title">
        <el-input v-model.trim="push.title" placeholder="请输入推文标题" maxlength="50" clearable />
      </el-form-item>

      <el-form-item label="发送对象" prop="sendTarget">
        <moe-select type="sendTargetList" v-model="push.sendTarget" placeholder="请选择发送对象" clearable />
      </el-form-item>

      <el-form-item label="指定用户" prop="sendTargetList" v-if="push.sendTarget === 'SPECIFY_USER'">
        <div class="df aic fww">
          <moe-tag class="mr-10 mb-10" v-for="(item, index) in push.sendTargetList" :key="index" closable @close="handleDelete(index, 'sendTargetList')">{{ filters(item) }}</moe-tag>
        </div>
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="handleAddUser('sendTargetList')">添加用户</el-button>
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="handleBatchImport('sendTargetList')">批量导入(excel)</el-button>
      </el-form-item>

      <el-form-item label="指定标签用户" prop="sendTagIdList" v-if="push.sendTarget === 'SPECIFY_TAG'">
        <el-select v-model="push.sendTagIdList" multiple filterable clearable placeholder="请选择用户标签">
          <el-option v-for="(item, index) in userTagList" :key="index" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="黑名单用户" prop="sendExcludeList">
        <div class="df aic fww">
          <moe-tag class="mr-10 mb-10" v-for="(item, index) in push.sendExcludeList" :key="index" closable @close="handleDelete(index, 'sendExcludeList')">{{ filters(item) }}</moe-tag>
        </div>
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="handleAddUser('sendExcludeList')">添加用户</el-button>
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="handleBatchImport('sendExcludeList')">批量导入(excel)</el-button>
        <div class="color-info font-10 lh1 mt-10">（这些用户不推送）</div>
      </el-form-item>

      <el-form-item label="消息类型" prop="pushType">
        <moe-select type="pushTypeList" v-model="push.pushType" placeholder="请选择消息类型" clearable />
      </el-form-item>

      <el-form-item label="推文图片" prop="image">
        <moe-upload-file
          v-model="push.image"
          ossKey="PUSH"
          :default-file-list="imageList"
          type="image"
          upload-text="上传图片"
          @change="() => $refs.pushForm.validateField('image')" />
      </el-form-item>

      <el-form-item label="推文内容" prop="content">
        <el-input v-model.trim="push.content" type="textarea" :rows="5" placeholder="请输入推文内容" maxlength="500" clearable />
      </el-form-item>

      <el-form-item label="跳转页面" prop="url">
        <moe-select type="navigateToUrlList" v-model="push.url" placeholder="请选择跳转页面" clearable />
      </el-form-item>

      <el-form-item label="编号" prop="urlId" v-if="$moe_data.navigateToIsValidator.includes(push.url)">
        <el-input v-model.trim="push.urlId" placeholder="请输入编号" maxlength="50" clearable />
      </el-form-item>

      <el-form-item label="发送类型" prop="pushOpportunity">
        <moe-radio-group :radioBoxList="$moe_data.pushOpportunityList" v-model="push.pushOpportunity"></moe-radio-group>
      </el-form-item>

      <el-form-item label="发送时间" prop="pushTime" v-if="push.pushOpportunity === 'TIMING'">
        <el-date-picker
          style="width: 100%;"
          placement="bottom-start"
          v-model="push.pushTime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="请选择发送时间" />
      </el-form-item>

      <template slot="tool">
        <el-button type="primary" icon="el-icon-upload2" :loading="pushLoad" @click="pushInfoSubmit()">{{ pushLoad ? '提交中' : '提交信息' }}</el-button>
      </template>
    </moe-form>

    <userDialog title="选择用户" :showDialog.sync="showDialog" @close="handleClose" :defaultSelectIds="defaultSelectIds"></userDialog>
  </moe-page>
</template>

<script>
import * as XLSX from 'xlsx/xlsx.mjs';
import userDialog from '@/views/UserManage/components/dialog.vue'
export default {
  name: 'PushAdd',
  components: {
    userDialog
  },
  data() {
    //推送信息
    const push = {
      title: '',
      sendTarget: '',
      sendTagIdList: [],
      sendTargetList: [],
      sendExcludeList: [],
      pushType: '',
      image: '',
      content: '',
      url: '',
      urlId: '',      // 路径参数?id=urlId 本地记录不提交后台
      pushOpportunity: 'NOW',
      pushTime: '',
    };

    //推送数据校验
    const verify = this.$moe_verify.verifyForm;
    const pushRules = {
      title: verify.isExtent(['请输入推文标题', '长度为1 ~ 30'], '1~30'),
      sendTarget: verify.isEmpty('请选择发送对象'),
      pushType: verify.isEmpty('请选择消息类型'),
      content: verify.isExtent(['请输入推文内容', '长度为1 ~ 500'], '1~500'),
      urlId: verify.isEmpty('请输入编号'),
      pushOpportunity: verify.isEmpty('请选择发送类型'),
      sendTargetList: verify.isEmpty('请添加指定用户'),
      sendTagIdList: verify.isEmpty('请添加指定标签用户'),
      pushTime: verify.isEmpty('请选择发送时间'),
    };

    return {
      push, //推送信息
      imageList: [],
      pushRules, //推送数据校验
      pushLoad: false, //提交状态
      source: '',
      showDialog: false,
      defaultSelectIds: [],
      userTagList: [],
    };
  },
  methods: {
    /** 添加用户 */
    handleAddUser(source) {
      this.source = source;
      switch (source) {
        case 'sendTargetList':
          this.defaultSelectIds = this.push.sendTargetList.map(({ id }) => id);
          break;
        case 'sendExcludeList':
          this.defaultSelectIds = this.push.sendExcludeList.map(({ id }) => id);
          break;
      }
      this.showDialog = true;
    },
    /** 批量导入 */
    handleBatchImport(source) {
      this.source = source;
      //创建一个隐藏的type=file的文件选择input
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      // 触发点击
      input.click()
      input.onchange = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = async (event) => {
          const data = new Uint8Array(event.target.result); // 更改此处，将ArrayBuffer转换为Uint8Array
          const workbook = XLSX.read(data, { type: 'buffer' }); // 更改此处，读取ArrayBuffer数据
          const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets['Sheet1'], { header: 1, raw: true, stubheader: true },  { user_name: 3, user_id: 2, user_phone: 1 }); // 需要设置header参数来指定第一行是标题行
          if (jsonData && jsonData.length) {
            this.formatterJsonData(jsonData);
          }
        }

        reader.readAsArrayBuffer(file);
      }
    },
    /** 处理批量导入表格收到的数据 */
    formatterJsonData(jsonData) {
      let findIndex = jsonData.findIndex((list) => list[0] === '序号');
      //1.过滤不符合条件的数据
      let filterData = jsonData.slice(findIndex + 1, jsonData.length).filter((list) => list.length >= 2)
      let result = filterData.map((list) => {
        return list.reduce(function (obj, item, index) {
          if (index === 0) {
            obj.index = item;
          } else if (index === 1) {
            obj.name = item;
          } else if (index === 2) {
            obj.id = item;
          } else if (index === 3) {
            obj.phone = item;
          }
          return obj;
        }, {})
      })
      switch (this.source) {
        case 'sendTargetList':
          result.forEach((item) => {
            let findItem = this.push.sendTargetList.find(({ id, phone }) => (item.id && id && String(item.id) === String(id)) || (item.phone && phone && String(item.phone) === String(phone)))
            if (!findItem) {
              this.push.sendTargetList.push(item)
            }
          })
          try {
            this.$refs['pushForm'].validateField('sendTargetList');
          } catch {
            console.log('error')
          }
          break;
        case 'sendExcludeList':
          result.forEach((item) => {
            let findItem = this.push.sendExcludeList.find(({ id, phone }) => (item.id && id && String(item.id) === String(id)) || (item.phone && phone && String(item.phone) === String(phone)))
            if (!findItem) {
              this.push.sendExcludeList.push(item)
            }
          })
          break;
      }
    },
    /** 下载批量导入模版 */
    handleBatchImportTemplate() {
      var link = document.createElement('a');
      link.href = `${process.env.VUE_APP_OSS_UPLOAD}/Management/template/message_push.xls`;
      link.download = '';
      link.target = '_black';
      link.click();
    },
    filters({ id, phone }) {
      if (id) {
        return `用户编号: ${id}`
      } else {
        return `用户手机号: ${phone}`
      }
    },
    /**
     * 提交推送信息
     **/
    pushInfoSubmit() {
      this.$refs['pushForm'].validate(() => {
        this.pushLoad = true;
        let params = this.$moe_lodash.cloneDeepData(this.push);
        if (params.url) {
          params.url = `${this.$moe_data.appDefaultUrl}${params.url}`;
          if (params.urlId) {
            params.url = `${params.url}?id=${params.urlId}`;
            delete params.urlId;
          }
        }

        if (params.sendTargetList.length) {
          params.sendTargetList = params.sendTargetList.map(({ id, phone }) => {
            return {
              id: id || '',
              phone: phone || ''
            }
          })
        }
        if (params.sendExcludeList.length) {
          params.sendExcludeList = params.sendExcludeList.map(({ id, phone }) => {
            return {
              id: id || '',
              phone: phone || ''
            }
          })
        }

        if (params.sendTarget === 'ALL') {
          params.sendTargetList.splice(0);
          params.sendTagIdList.splice(0);
        } else if (params.sendTarget === 'SPECIFY_USER') {
          params.sendTagIdList.splice(0);
        } else if (params.sendTarget === 'SPECIFY_TAG') {
          params.sendTargetList.splice(0);
        }

        if (params.pushOpportunity === 'NOW') {
          params.pushTime = '';
        }
        this.$moe_api.PUSH_API.pushAdd(params)
          .then((data) => {
            if (data.code == 200) {
              this.$moe_msg.success('推送成功', {
                completionHandler :() => {
                  this.$moe_coordinator.navigateBack();
                }
              });
            } else {
              this.$moe_msg.error(data.message);
            }
          })
          .finally(() => {
            this.pushLoad = false;
          });
      });
    },
    handleClose(selectList) {
      switch (this.source) {
        case 'sendTargetList':
          selectList.forEach((item) => {
            let findItem = this.push.sendTargetList.find(({ id }) => item.id === id);
            if (!findItem) {
              this.push.sendTargetList.push(item);
            }
          })
          try {
            this.$refs['pushForm'].validateField('sendTargetList');
          } catch {
            console.log('error')
          }
          break;
        case 'sendExcludeList':
          selectList.forEach((item) => {
            let findItem = this.push.sendExcludeList.find(({ id }) => item.id === id);
            if (!findItem) {
              this.push.sendExcludeList.push(item);
            }
          })
          break;
      }

      this.showDialog = false;
    },
    /** 删除value */
    handleDelete(index, source) {
      switch (source) {
        case 'sendTargetList':
          this.push.sendTargetList.splice(index, 1);
          break;
        case 'sendExcludeList':
          this.push.sendExcludeList.splice(index, 1);
          break;
      }
    },
    /** 获取所有用户标签 */
    async req_getUserTagListAll() {
      const { code, result, message } = await this.$moe_api.USER_API.getUserTagListAll()
      if (code === 200) {
        this.userTagList = result.list.map(({ name, id }) => {
          return {
            label: name,
            value: id
          }
        })
      } else {
        this.$moe_msg.error(message);
      }
    }
  },
  mounted() {
    this.req_getUserTagListAll();
  },
};
</script>

<style lang="scss">

</style>
